
.formulario {
    padding: 25px 30px;
    background-color: #0000001a;
    min-height: calc(100% - 58px);
  border-radius: 4px;
}

.formulario h2{
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 1.2;
}

.formulario label {
  display: block;
  margin-bottom: 5px;
  font-size: 18px;
}


.formulario input[type="text"],
.formulario input[type="email"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.formulario input[type="checkbox"] {
  margin-top: 5px;
}

.formulario button,
.home-container a {
  padding: 14px 24px;
  text-transform: uppercase;
  background-color: #ccbd9b;
  color: #fff;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.formulario button:hover,
.home-container a:hover {
  background-color: #b1a487;
}

label.newsletter-check{
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}