.body-wrapper {
  min-height: 80vh;
  background-color: #c0c0c0;
}

.body-container {
  max-width: 1250px;
  padding: 0 20px;
  margin: auto;
  color: rgb(24, 24, 24);
}

h2{
  font-family: "Playfair Display", "BlinkMacSystemFont", -apple-system, "Roboto",
    "Lucida Sans";
    color: #343434;
    font-size: 32px;
    line-height: 1.3 !important;
}

p{
    font-family: "futura-pt-light", "BlinkMacSystemFont", -apple-system, "Roboto",
    "Lucida Sans";
    font-size: 20px;
    line-height: 1.6;
}


.playfair {
  font-family: "Playfair Display", "BlinkMacSystemFont", -apple-system, "Roboto",
    "Lucida Sans";
}

@font-face {
  font-family: "futura-pt-bold";
  src: url("./assets/fonts/Futura/FuturaPTBold.otf") format("opentype");
}

@font-face {
  font-family: "futura-pt-light";
  src: url("./assets/fonts/Futura/FuturaPTLight.otf") format("opentype");
}

@font-face {
  font-family: "futura-pt-medium";
  src: url("./assets/fonts/Futura/FuturaPTMedium.otf") format("opentype");
}

@font-face {
  font-family: "futura-pt-regular";
  src: url("./assets/fonts/Futura/FuturaPTMedium.otf") format("opentype");
}

.main-btn {
  background: #ee9982;
  background-color: #ccbd9b;
  color: #ffffff;
  font-family: "futura-pt-light";
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 600;
  height: 48px;
  line-height: 48px;
  padding: 0 40px;
  text-transform: uppercase;
}

.main-btn:hover {
  background-color: #beb090;
}

.header-body-footer-width {
  max-width: 1350px !important;
  padding: 60px 55px !important;
  margin: auto !important;
}

.header-container {
  padding: 0 55px !important;
}

.header-background {
  min-height: 90px;
  background-color: rgb(0, 0, 0);
}

.menu-btn-scroll{
      font-family: "futura-pt-medium";
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
        position: relative;
    text-decoration: none;
    transition: color 0.5s ease, border-bottom-color 0.3s ease;
    background-color: transparent;
}

.menu-btn-scroll:hover{
  color: #beb090;
  border-bottom: 1px solid #fff;
}

.footer-menu .menu-btn-scroll{
  width: fit-content !important;
}

@media (max-width: 630px) {
  .header-body-footer-width {
    padding: 60px 20px !important;
  }
  .header-container {
    padding: 0 20px !important;
  }

  h2{
    font-size: 26px;
  }

  .formulario h2{
    font-size: 20px !important;
  }

  .formulario{
    padding: 20px !important;
  }

  .home-wrapper{
    margin-top: 80px !important;
    padding-top: 60px !important;
  }

  .home__shadow{
    margin-bottom: 20px !important;
  }

  .home-container.alt{
    margin-top: 40px !important;
    padding: 20px !important;
  }

  .chatbot-container{
    padding: 0 !important;
  }

  .chatbot-header{
    font-size: 24px !important;
    margin-bottom: -20px !important;
    padding: 0 20px;
  }

  .descricao{
    gap: 15px !important;
  }

  iframe{
    min-height: 755px !important;
  }

}

