.home-wrapper{

    margin-top: 90px;
    padding-top: 80px;

}

.home-container{
    padding: 0 60px;
    display: flex;
    max-width: 1150px;
    margin: auto;
    gap: 20px;
    justify-content: space-between;
}

.home-container div{
    flex: 1;
}

.left-col-container{
    max-width: 480px;
}

.right-col-container{
    max-width: 550px;
}

.left-col-container img{
    max-width: 400px;
    display: block;
    margin: auto;
    margin-bottom: 10px;
    border-radius: 4px;
}

.home__shadow{
    background-color: #050000;
    border: 4px solid #000;
    border-radius: 50%;
    color: #000;
    -webkit-filter: blur(10px);
    filter: blur(10px);
    margin: 10px auto;
    margin-top: 0;
    margin-bottom: 40px;
    max-width: 300px;

}

.left-col-container h2{
    line-height: 1.2;
}

.left-col-container p{
    font-size: 26px;
    line-height: 1.3;
}


.home-container.alt{
    flex-direction: column;
    gap: 0px;
    font-size: 18px;
    padding: 0;
    margin: auto;
    padding: 20px 40px;
    
}

.home-container.alt p{
    line-height: 1.3;
}

.impresso-container{
    text-align: center;
}

.impresso-links{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: center;
}

.impresso-links a{
    width: fit-content !important;
    min-width: 175px;
    text-align: center;
}

.descricao{
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
    font-size: 18px;
    line-height: 1.3;
    padding: 0 20px;
    margin-bottom: 60px;

}

.descricao button{
    background-color: transparent;
    text-decoration: underline;
    font-size: 16px;
}



@media (max-width: 1000px){
    .home-container{
        flex-direction: column;
        gap: 0;
        align-items: center;
        padding: 0 20px;
    }
}