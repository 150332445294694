/* Replicação do App.css para promover desacoplamento */

.header-body-footer-width {
  max-width: 1350px;
  padding: 60px 55px;
}

@media (max-width: 630px) {
  .header-body-footer-width {
    padding: 60px 20px;
  }
}

/* Fim da replicação */

.footer-background {
  margin-top: 60px;
  background-color: #1a1b1f;
  background-image: url("../../assets/imgs/footer_bg.png");
  background-repeat: repeat !important;
}

.footer-container .footer-container-division {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin: auto;
}

.footer-container h5 {
  color: #ccbd9b;
  font-size: 12px;
  margin-bottom: 25px;
  font-weight: 400;
  font-family: "futura-pt-regular";
}

.footer-container h4 {
  color: #fff;
  font-family: "Playfair Display";
  font-weight: 500;
  font-size: 24px;
}

.footer-container a,
.footer-menu button {
  color: rgba(255, 255, 255, 0.8);
  font-family: "futura-pt-light";
  font-size: 15px;
  max-width: fit-content;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  text-transform: none;
}

.footer-container a:hover,
.footer-menu button:hover {
  color: #ccbd9b;
  border-bottom: 1px solid #fff;
}

.footer-menu div {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.footer-container p {
  color: rgba(255, 255, 255, 0.8);
  font-family: "futura-pt-light";
  font-size: 14px;
  line-height: 1.6;
}

.footer-endereco span {
  display: block;
  font-family: "futura-pt-bold";
  margin-bottom: 5px;
  color: #ffffffcc;
}

.footer-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-wrap div {
  flex: 1;
}

.footer-container .newsletter-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-content div {
  min-width: 350px;
  margin-bottom: 40px;
}

.footer-container input {
  border: 1px solid rgba(0, 0, 0, 0.07);
  padding: 15px 20px;
  color: #343434;
  font-size: 14px;
  font-weight: 400;
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.15);
  font-size: 16px;
  color: #c1c1c1;
  width: 100%;
  max-width: 100%;
  outline: 0;
  font-family: "futura-pt-light";
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  max-width: 460px;
}

.footer-img {
  width: auto;
  height: 70px;
}

.footer-socials {
  display: flex;
  gap: 10px;
  justify-content: right;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.footer-socials div {
  height: 40px;
  width: 40px;
  max-height: 40px;
  max-width: 40px;
  background-color: #fff;
}

.footer-bottom-container {
  align-items: center;
  justify-content: space-between;
  opacity: 0.6;
}

.footer-container .main-btn {
  text-transform: none;
}

.footer-menu .menu-btn-scroll {
  width: fit-content !important;
}

@media (max-width: 450px) {
  .footer-content div {
    min-width: 210px;
  }
}

@media (max-width: 1000px) {
  .footer-wrap {
    flex-direction: column;
    gap: 20px;
  }

  .footer-bottom-container {
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 60px;
  }

  .footer-container  .newsletter-container {
    margin-top: 20px;
  }

  .footer-menu {
    margin-bottom: 0 !important;
  }
}

  .footer-img.alt{
  display: none;
  }

@media (max-width: 380px){
  .footer-img{
    display: none;
  }
  .footer-img.alt{
  display: block;
  }
}